import { LocationServices, Disposable } from '@uirouter/core';
import type { NavOptions } from '@wix/yoshi-flow-editor';

import type { ILocation } from './types';

/**
 * Default location service for UI Router
 */
export class LocationService implements LocationServices, Disposable {
  private listeners: EventListener[] = [];

  constructor(protected location: ILocation) {}

  /**
   * notify ui-router about external location update
   */
  notifyListeners = () => {
    this.listeners.forEach((listener) => listener(new Event('locationChange')));
  };

  /**
   * Returns full internal url with search and hash
   * if newUrl is provided, it will call `set` method
   */
  url(newUrl?: string, replace?: boolean, state?: any) {
    const hash = this.hash();
    const pathname = this.path();
    const search = new URLSearchParams(this.search()).toString();

    const url = [
      pathname,
      search ? `?${search}` : '',
      hash ? `#${hash}` : '',
    ].join('');

    if (!!newUrl && newUrl !== url) {
      this.set(newUrl, {
        disableScrollToTop: hash !== 'scrollToTop',
      });
    }

    return url;
  }

  /**
   * Persist client's url i.e. via window.location.pushState
   */
  set(url: string, options?: NavOptions) {}

  hash() {
    const url = new URL(this.location.url);

    return url.hash.replace(/^#/, '');
  }

  /**
   * Get current "internal" path
   * should not include baseUrl
   */
  path() {
    const { path } = this.location;

    const pathname = path.filter((entry) => !!entry).join('/'); // remove double slashes

    return `/${pathname}`;
  }

  search() {
    return this.location.query;
  }

  dispose() {
    this.listeners.forEach((listener) => listener(null as any));
    this.listeners = [];
  }

  /**
   * @ui-router/core is subscribed to updates via this method
   */
  onChange(listener: EventListener) {
    this.listeners.push(listener);

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }
}
