import {
  StyleParamType,
  wixFontParam,
  createStylesParam,
} from '@wix/tpa-settings';

import * as primary from './primary';
import * as secondary from './secondary';

export const styles = {
  ...primary.styles,
  ...secondary.styles,

  buttonTextFont: createStylesParam('buttonTextFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  }),

  mobileButtonTextFontSize: createStylesParam('mobileButtonTextFontSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: (key) => key,
  }),
};
