import { pick } from 'lodash';
import React, { useState } from 'react';

import type { IVMMethods } from 'vm/types';

import { ControllerContext } from './ControllerContext';

interface IControllerProviderProps extends IVMMethods {
  children: React.ReactNode;
}

export function ControllerProvider(props: IControllerProviderProps) {
  const { children } = props;

  const [value] = useState(
    pick(
      props,
      'application$',
      'centralFeed$',
      'events$',
      'comments$',
      'feed$',
      'files$',
      'group$',
      'groups$',
      'media$',
      'members$',
      'router$',
      'topics$',
      'groupRequests$',
      'pricingPlans$',
    ),
  );

  return (
    <ControllerContext.Provider value={value}>
      {children}
    </ControllerContext.Provider>
  );
}

ControllerProvider.displayName = 'ControllerProvider';

export function useController() {
  return React.useContext(ControllerContext);
}
