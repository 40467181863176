import { EGroupPartition } from 'api/groups/types';

import { useSettings } from '@wix/tpa-settings/react';
import { LayoutType } from 'settings/consts';
import settingsParams from 'Groups/settingsParams';
import { useCurrentStateAndParams } from 'router/react';

export const groupsScreenMap: { [key in LayoutType]: string } = {
  [LayoutType.list]: 'list_layout',
  [LayoutType.grid]: 'grid_layout',
  [LayoutType.sideBar]: 'sidebar_layout',
  [LayoutType.listWidget]: 'list_layout',
  [LayoutType.sideBySide]: 'side_by_side_layout',
};

const groupsTabNameMap: { [key in EGroupPartition]: string } = {
  [EGroupPartition.ALL]: 'all',
  [EGroupPartition.JOINED]: 'my_groups',
  [EGroupPartition.SUGGESTED]: 'suggested_groups',
};

export function useBiParams() {
  const settings = useSettings();

  const { params } = useCurrentStateAndParams();

  const groupsScreen =
    groupsScreenMap[settings.get(settingsParams.groupListLayout) as LayoutType];

  const activeTab = (partition: EGroupPartition) => groupsTabNameMap[partition];

  return {
    groupsScreen,
    activeTab,
    groupsScreenWithTab: (partition: EGroupPartition = params.partition) => {
      // partition could be undefined for group list widgets
      if (partition) {
        return `${groupsScreen}_${activeTab(partition)}`;
      }
      return groupsScreen;
    },
  };
}
