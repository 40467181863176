import type { UIRouter } from '@uirouter/core';

import { ILocation } from '../../core';
import { commonServicesPlugin } from '../common-services';
import { clientLocationPlugin } from '../client-location';

export function clientPlugin(location: ILocation) {
  return function (router: UIRouter) {
    router.plugin(commonServicesPlugin());
    router.plugin(clientLocationPlugin(location));

    return {
      name: 'WixUIRouterClient',
    };
  };
}
