import type { UIRouter } from '@uirouter/core';

import { ILocation } from '../../core';

import { LocationConfig } from './LocationConfig';
import { LocationService } from './LocationService';

export function clientLocationPlugin(location: ILocation) {
  return function (router: UIRouter) {
    router.locationConfig = new LocationConfig(location);
    router.locationService = new LocationService(location);

    return {
      name: 'WixUIRouterClientLocation',
    };
  };
}
