import { type UIRouter, servicesPlugin } from '@uirouter/core';

import { URIEncoded } from './param-types';

export function commonServicesPlugin() {
  return function (router: UIRouter) {
    router.plugin(servicesPlugin);

    router.urlService.deferIntercept();
    router.urlService.config.type('URIEncoded', URIEncoded);

    return {
      name: 'WixUIRouterCommonServices',
    };
  };
}
