import { ISettingsParam } from '@wix/tpa-settings';
import { ImageCrop, ImageLayout, TextAlignment } from '../consts';
import groupsSettingsParams from 'Groups/settingsParams';

export const WIDGET_NUM_GROUPS = 3;
export const WIDGET_MAX_NUM_GROUPS = 9;
export const TEXT_SPACING = 100;

export enum GroupsSelectorType {
  ALL = 'All',
  CUSTOM = 'Custom',
}

export interface IGroupsListWidgetSettingsParams {
  sortBy: ISettingsParam<string>;
  imageRatio: ISettingsParam<string>;
  groupListLayout: ISettingsParam<string>;
  gridCardSpacing: any;
  imageLayout: ISettingsParam<ImageLayout>;
  imageCrop: ISettingsParam<ImageCrop>;
  textAlignment: ISettingsParam<TextAlignment>;
  textSpacing: ISettingsParam<number>;

  numberGroups: ISettingsParam<number>;
  groupsSelectorType: ISettingsParam<GroupsSelectorType>;
  groupIdsToShow: ISettingsParam<string>; // coma,separated values

  showGroupType: ISettingsParam<boolean>;
  showActivity: ISettingsParam<boolean>;
  showButton: ISettingsParam<boolean>;
}

export const groupsListWidgetSettingsParams: IGroupsListWidgetSettingsParams = {
  /**
   * Widget Layout settings
   */
  sortBy: groupsSettingsParams.sortBy,
  numberGroups: {
    getDefaultValue: () => WIDGET_NUM_GROUPS,
    key: 'numberGroups',
  },
  groupsSelectorType: {
    getDefaultValue: () => GroupsSelectorType.ALL,
    key: 'groupsSelectorType',
  },
  groupIdsToShow: {
    getDefaultValue: () => '',
    key: 'groupIdsToShow',
  },
  // TODO: migrate to styleParams
  imageRatio: groupsSettingsParams.imageRatio,
  groupListLayout: groupsSettingsParams.groupListLayout,
  // TODO: migrate to styleParams
  gridCardSpacing: groupsSettingsParams.gridCardSpacing,
  // TODO: migrate to styleParams
  imageCrop: {
    getDefaultValue: () => ImageCrop.CROP,
    key: 'imageCrop',
  },
  // TODO: migrate to styleParams
  imageLayout: {
    getDefaultValue: () => ImageLayout.LTR,
    key: 'imageLayout',
  },
  // TODO: migrate to styleParams
  textAlignment: {
    getDefaultValue: () => TextAlignment.Left,
    key: 'textAlignment',
  },
  // TODO: migrate to styleParams
  textSpacing: {
    getDefaultValue: () => TEXT_SPACING,
    key: 'textSpacing',
  },
  /**
   * Widget Design settings
   */
  // @deprecated use styleParam instead
  showActivity: {
    getDefaultValue: () => true,
    key: 'showActivity',
  },
  // @deprecated use styleParam instead
  showGroupType: {
    getDefaultValue: () => true,
    key: 'showGroupType',
  },
  // @deprecated use styleParam instead
  showButton: {
    getDefaultValue: () => true,
    key: 'showButton',
  },
};
