export const GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';
export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export const INVITE_PAID_PLANS = 'invite_paid_plans';

export const GROUPS_DASHBOARD_URL = 'social-groups-dashboard';

export const WIX_STATIC = 'https://static.wixstatic.com';
export const WIX_STATIC_VIDEO_BASE_URL = 'https://video.wixstatic.com/video';
