import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParam,
} from '@wix/tpa-settings';
import { FontTheme } from 'settings/consts';

export const styles = {
  textFieldBackgroundColor: createStylesParam('textFieldBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),

  textFieldBorderColor: createStylesParam('textFieldBorderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  }),

  textFieldBorderWidth: createStylesParam('textFieldBorderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),

  textFieldBorderRadius: createStylesParam('textFieldBorderRadius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),

  textFieldPlaceholderColor: createStylesParam('textFieldPlaceholderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  }),

  textFieldFont: createStylesParam('textFieldFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, { size: 14 }),
  }),

  textFieldColor: createStylesParam('textFieldColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
};
