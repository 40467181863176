import { LocationConfig as ILocationConfig } from '@uirouter/core';

import type { ILocation } from './types';

/**
 * Default location config for UI Router
 */
export class LocationConfig implements ILocationConfig {
  constructor(protected location: ILocation) {}

  port() {
    return this.location.protocol === 'https' ? 443 : 80;
  }

  /**
   * should return baseUrl with trailing slash and should not include host
   * i.e. for https://maksymb.wixsite.com/wix-groups-test-site/group/private-group/discussion
   * it should return /wix-groups-test-site/
   */
  baseHref() {
    const url = new URL(this.location.baseUrl);

    return url.pathname.endsWith('/') ? url.pathname : `${url.pathname}/`;
  }

  protocol() {
    return this.location.protocol;
  }

  host() {
    const url = new URL(this.location.url);
    return url.host;
  }

  html5Mode() {
    return true;
  }

  hashPrefix() {
    return '';
  }

  dispose() {}
}
