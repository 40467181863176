import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UIRouter } from '@uirouter/core';

import type { IRootState } from 'store/types';

import { selectStateDeclarations } from 'store/selectors';

import { clientPlugin } from '../../plugins/client';
import { RouterContext } from '../context';

interface IRouterProps {
  children: React.ReactNode;
}

export function Router(props: IRouterProps) {
  const { children } = props;

  const location = useSelector(
    (state: IRootState) => state.application.router.location,
  );

  const states = useSelector(selectStateDeclarations);

  const router = useMemo(() => {
    const router = new UIRouter();

    router.plugin(clientPlugin(location));

    for (const state in states) {
      router.stateRegistry.register(states[state]);
    }

    return router;
  }, []);

  useEffect(() => {
    return () => router.dispose();
  }, []);

  return (
    <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  );
}

Router.displayName = 'Router';
