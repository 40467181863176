import React from 'react';
import { useSelector } from 'react-redux';
import type { HrefOptions, RawParams, StateOrName } from '@uirouter/core';

import type { IRootState } from 'store/types';
import { useController } from 'common/context/controller';

import { RouterContext } from '../context';

import { useCurrentStateAndParams } from './useCurrentState';

export function useRouter() {
  const router = React.useContext(RouterContext);

  const { router$ } = useController();
  const current = useCurrentStateAndParams();

  const states = useSelector(
    (state: IRootState) => state.application.router.states,
  );

  return {
    states,

    /**
     * Directly calls router in controller
     */
    go: router$.go,

    /**
     * Directly calls router in controller
     */
    reload: router$.reload,

    /**
     * Please note that state params can not be inherited from the current state
     * since the router in react is not aware of the current state.
     */
    href(state: StateOrName, params: RawParams, options: HrefOptions) {
      const { inherit = true } = options;
      const shouldScroll = isAnotherComponent();

      return router.stateService.href(
        state,
        {
          ...(inherit ? current.params : {}),
          '#': shouldScroll ? 'scrollToTop' : undefined,
          ...params,
        },
        options,
      );

      function isAnotherComponent() {
        try {
          const to = router.stateRegistry.get(state);
          const from = router.stateRegistry.get(current.state.name as string);

          return from?.data?.sectionId !== to?.data?.sectionId;
        } catch {
          return false;
        }
      }
    },
  };
}
