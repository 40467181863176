import { useSelector } from 'react-redux';

import type { IRootState } from 'store/types';

export function useCurrentStateAndParams() {
  const { current, states } = useSelector(
    (state: IRootState) => state.application.router,
  );

  return {
    params: current.params,
    state: states[current.state],
  };
}
