import React from 'react';
import type { HrefOptions, RawParams, StateOrName } from '@uirouter/core';

import { useRouter } from './useRouter';

export function useHref(
  state: StateOrName,
  params: RawParams = {},
  options: HrefOptions = {},
) {
  const router = useRouter();

  const href = React.useMemo(() => {
    return router.href(state, params, options);
  }, [JSON.stringify(state), JSON.stringify(params), JSON.stringify(options)]);

  return href;
}
