import { LocationService as BaseLocationService, ILocation } from '../../core';

export class LocationService extends BaseLocationService {
  constructor(protected location: ILocation) {
    super(location);
  }

  set(): void {
    throw new Error(
      'UI.Router navigation is not supported inside Viewer client',
    );
  }
}
